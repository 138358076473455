@import '../../App.scss';

.container {
  text-align: center;
}

.guessBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 215px;
  color: $clr-white;
  position: relative;
  cursor: pointer;

  .guessOval {
    background-color: $clr-white;
    border-radius: 50%;
    padding: 1rem;

    h1 {
      font-size: 1.25rem;
      position: absolute;
      top: 0;
      left: 0;
      color: $clr-white;
      border: 1px solid $clr-white;
      border-radius: 50%;
      padding: 0.25rem;
    }

    .hide {
      width: 100px;
      filter: grayscale(10%);
      filter: brightness(0%);
    }

    .reveal {
      width: 100px;
      animation-name: shake;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }

    @keyframes shake {
      0% { transform: translate(1px, 1px) rotate(0deg); }
      10% { transform: translate(-1px, -2px) rotate(-1deg); }
      20% { transform: translate(-3px, 0) rotate(1deg); }
      30% { transform: translate(3px, 2px) rotate(0deg); }
      40% { transform: translate(1px, -1px) rotate(1deg); }
      50% { transform: translate(-1px, 2px) rotate(-1deg); }
      60% { transform: translate(-3px, 1px) rotate(0deg); }
      70% { transform: translate(3px, 1px) rotate(-1deg); }
      80% { transform: translate(-1px, -1px) rotate(1deg); }
      90% { transform: translate(1px, 2px) rotate(0deg); }
      100% { transform: translate(1px, -2px) rotate(-1deg); }
    }
  }

  .subtitle {
    color: $clr-yellow-gold;
    font-size: 2.5rem;
    text-decoration: none;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .guessBox {
    padding: 0 4rem;

    .guessOval {
      padding: 2rem;
    }

    .subtitle {
      margin-left: 1rem;
      font-size: 2.5rem;
      text-decoration: none;
      text-align: center;
    }
  }
}
