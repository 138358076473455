@import '../../App.scss';

footer {
  text-align: center;
  color: $clr-white;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $clr-black-two;
  padding: 0 0 0.25rem 0;

  a {
    color: $clr-yellow-gold;
    text-decoration: none;
    margin-left: 0.5rem;
  }
}
