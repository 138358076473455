@import '../../App.scss';
@import './Details.scss';

.background {
  display: flex;
  flex-direction: row;
  background-color: $clr-black-two;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    background-color: transparent;
  }

  .closeIcon {
    color: $clr-white;
    font-size: 1rem;
  }

  .statsContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem;
    list-style-type: none;
    width: 55%;

    .property {
      margin: 0 0 0 1rem;
      color: $clr-white;
      display: flex;
      justify-content: space-between;
    }

    .info {
      color: $clr-yellow-gold;
    }
  }
}

@media screen and (min-width: 768px) {
  .background {
    justify-content: center;
  }
}
